'use-strict';

module.exports = function () {
    $('.fc-nav-item.fc-megamenu')
        .on('mouseenter', function (e) {
            $(this).addClass('show');
        })
        .on('mouseleave', function (e) {
            $(this).removeClass('show');
        })

    /**
     * MENU MOBILE
     */
    $('.menu-toggler').on('click', function (e) {
        $('.main-menu').addClass('show').addClass('opened')
    })
    $('.close-button button').on('click', function (e) {
        $('.main-menu').removeClass('opened')
        setTimeout(() => {
            $('.main-menu').removeClass('show')
        }, 1000);
    })
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.main-menu').length && $('.main-menu').hasClass('opened') && !$(event.target).hasClass('menu-toggler')) {
            $('.main-menu').removeClass('opened')
            setTimeout(() => {
                $('.main-menu').removeClass('show')
            }, 300);
        }
    });

    // open menù mobile
    $('.menu-toggler').click(function (e) {
        e.preventDefault();
        manageCategoryMenuMobile()
        
        $('body').addClass('menu-open');
        $('.main-menu.multilevel-dropdown').css('overflow-y', 'auto')
        // $('.main-menu').attr('aria-hidden', 'false');
        // $('.main-menu').siblings().attr('aria-hidden', 'true');
        // $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    // manage click categories of first level on mobile
    $('.custom-dropdown-link.custom-dropdown-toggle:not(.nav-link)').on('click', function(e) {
        if (helpers.isMobile()) {
            e.preventDefault()
            $(this).closest('li').toggleClass('active')
            $(this).closest('li').find('.custom-dropdown-menu').toggleClass('active')
        }
    })

    function manageCategoryMenuMobile() {
        $('.main-menu .fc-navbar-nav').wrap('<div class="fc-wrapper-navbar-nav"></div>');
        var $navItems = $('.main-menu').find('.fc-navbar-nav .fc-nav-item .fc-nav-link');
        // var menuActive = helpers.menuStorage.getActive();
    
        $navItems.on('click', function(e) {
            var $mainMenu = $('.main-menu')
            var $navMenu = $mainMenu.find('.fc-wrapper-navbar-nav')
            var $navItem = $(this).closest('li')
    
            var $dropdownMenu = $navItem.find('.fc-megamenu-container')
    
            if ($navItem.hasClass('active') || $dropdownMenu.length == 0) {
                return
            } else {
                e.preventDefault()
                // helpers.menuStorage.setActive($(this).attr('id'));
    
                var $navItemAlredyActive = $('.main-menu').find('.fc-nav-item.active')
                if ($navItemAlredyActive.length) {
                    $navItemAlredyActive.append($('.fc-menu-group > .fc-megamenu-container').removeClass('active'))
                    $navItemAlredyActive.removeClass('active')
                }
                
                $navItem.addClass('active')
                // if ($navItem.data('coloractivemobile')) {
                //     $navItem.css({"border-bottom": `5px solid ${$navItem.data('coloractivemobile')}`})
                // }
                $dropdownMenu.addClass('active')
    
                // if (helpers.isMobile()) {
                //     expandCategoryFromContentAsset($navItem);
                // }
    
                $dropdownMenu.insertAfter($navMenu);
            }
        })
        // if (menuActive) {
        //     $(`.nav-link#${menuActive}`).click();
        // } else if ($('.main-menu').find('.menu-group .nav-item.active').length == 0) {
        //     $navItems.first().click()
        // }
    }
}
